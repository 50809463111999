<template>
  <div>
    <h2>Upload login data</h2>
    <ion-label>Vil du opdatere login data?</ion-label>
    <br />
    <input
      type="file"
      id="fileInput"
      ref="file"
      accept=".csv"
      @change="fileReader()"
    />
    <hr />
    {{ data.length }} / {{ csvArray.length }}

    <hr />
    <div v-if="fileDone">
      <ion-button
        expand="block"
        @click="saveLoginData()"
      >Gem data</ion-button>
    </div>
    <br />
    <ion-label
      color="primary"
      v-if="loginMessage.length > 0"
    >{{ loginMessage }}</ion-label>
  </div>
</template>

<script>
import Papa from "papaparse";

import { mapGetters } from "vuex";
import { IonButton, IonLabel } from "@ionic/vue";

export default {
  components: {
    IonButton,
    IonLabel,
  },
  data() {
    return {
      fileDone: false,
      fileName: "",
      csvArray: [],
      data: [],
      loginMessage: "",
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    saveLoginData() {
      var app = this;
      this.$store.commit("saveLoginData", this.data);

      var result = this.$store.dispatch("saveToBlobStorage", {
        data: this.data,
        blobName: "login",
      });

      result.then(function (data) {
        app.loginMessage = data;
      });
    },
    fileReader() {
      var app = this;
      this.message = "Loading";
      this.loading = true;
      var oFile = this.$refs.file.files[0];

      //reset
      this.data = [];
      this.csvArray = [];

      Papa.parse(oFile, {
        header: true,
        download: true,
        dynamicTyping: true,
        complete: function (results) {
          app.csvArray = results.data;

          for (let i = 0; i < app.csvArray.length; i++) {
            const element = app.csvArray[i];
            app.data.push(element);

            if (i + 1 == app.csvArray.length) {
              app.fileDone = true;
            }
          }
        },
      });
    },
  },
};
</script>
